export const aboutDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/Product/SBKH1.jpg'),
		active: require('@/assets/img/Product/SBKH2.jpg'),
		series: '浮波系列',
		seriesName: '水波口红',
		zhName: '酵色「水波口红」',
		enName: 'Sand Ripple Matte Lip Rouge',
		intro: '柔雾哑光质地 奶油感红唇',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		price: '79',
		of: '3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/水波口红素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/水波口红素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/水波口红素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/水波口红素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/1.jpg') },
		],
		color: [
			{
				active: '#823d3f',
				name: '#101 瓦砾回声',
				span1: '灰调陶土奶咖 丧甜超模',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/01.jpg')
			},
			{
				active: '#a2514d',
				name: '#102 赤茶冥想',
				span1: '土调红棕 慵懒气质',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/02.jpg')
			},
			{
				active: '#be4347',
				name: '#103 杏桃冻干',
				span1: '粉雾感杏桃 甜美清爽',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/03.jpg')
			},
			{
				active: '#a33e4a',
				name: '#104 暮色花市',
				span1: '烟熏紫豆沙 轻熟迷人',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/04.jpg')
			},
			{
				active: '#994c38',
				name: '#105 日落苏打',
				span1: '复古黄棕调 油画质感',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/05.jpg')
			},
			{
				active: '#cc2347',
				name: '#106 羞怯甜莓',
				span1: '粉紫莓果 冷调迷情',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/06.jpg')
			},
			{
				active: '#7e0920',
				name: '#107 沉溺夜月',
				span1: '蓝调浆果 叛逆俏皮',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/07.jpg')
			},
			{
				active: '#aa2929',
				name: '#108 持有天真',
				span1: '艳阳苹果红 活力满满',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/08.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/Product/SFB1.jpg'),
		active: require('@/assets/img/Product/SFB2.jpg'),
		series: '眼部',
		seriesName: '水粉笔',
		zhName: '酵色「水粉笔」',
		enName: 'Liquid Eye-liner',
		intro: '低饱和莫兰迪色 哑光粉雾妆效',
		price: '59',
		of: '1',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/眼线液笔/小图/4.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/眼线液笔/小图/2.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/3.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/1.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/01.jpg') },
		],
		color: [
			{
				active: '#a09470',
				name: '#01 听茶',
				span1: '特调橄榄绿',
				span2: '灵动点缀 打破沉闷 ',
				color: require('@/assets/img/详情/眼线液笔/01.jpg'),
			},
			{
				active: '#c0888e',
				name: '#02 酒渍',
				span1: '冷调灰紫色',
				span2: '微醺甜酒',
				color: require('@/assets/img/详情/眼线液笔/02.jpg')
			},
			{
				active: '#776362',
				name: '#03 微尘',
				span1: '清透灰蓝调',
				span2: '低调高级',
				color: require('@/assets/img/详情/眼线液笔/03.jpg')
			},
			{
				active: '#b07574',
				name: '#04 烟霞',
				span1: '藕粉豆沙色',
				span2: '眼间温柔',
				color: require('@/assets/img/详情/眼线液笔/04.jpg')
			},
			{
				active: '#c37f67',
				name: '#05 花间',
				span1: '木质苦橙色',
				span2: '暖调烘焙感',
				color: require('@/assets/img/详情/眼线液笔/05.jpg')
			},
			{
				active: '#906f62',
				name: '#06 木偶',
				span1: '松露灰棕色',
				span2: '低饱和下的丰富层次',
				color: require('@/assets/img/详情/眼线液笔/06.jpg')
			}
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/Product/SCCY1.jpg'),
		active: require('@/assets/img/Product/SCCY2.jpg'),
		series: '唇部',
		seriesName: '丝绸唇釉',
		intro: '不沾杯唇泥质地  持久柔雾妆效',
		zhName: '酵色「丝绸唇釉」',
		enName: 'Silky Silk Matte Lipgloss',
		price: '79',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		of: '3.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/4.jpg') },
		],
		color: [
			{
				active: '#b16750',
				name: '#M01 不眠岛屿',
				span1: '土调杏色 干净高级',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/01.jpg')
			},
			{
				active: '#c36356',
				name: '#M02 迷路烟杏',
				span1: '蜜桃奶杏色 素颜轻松驾驭',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/02.jpg')
			},
			{
				active: '#b1464d',
				name: '#M03 心碎豆沙',
				span1: '冷烟粉豆沙 软糯奶糖少女',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/03.jpg')
			},
			{
				active: '#a52310',
				name: '#M04 红釉碎片',
				span1: '热辣血橙色 时髦气场',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/04.jpg')
			},
			{
				active: '#6f3324',
				name: '#M05 土星光环',
				span1: '栗子牛奶 个性酷感',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/05.jpg')
			},
			{
				active: '#d83523',
				name: '#M06 流浪悬日',
				span1: '热辣血橙色 亮眼霓虹',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/06.jpg')
			},
			{
				active: '#da353c',
				name: '#M07 失重春天',
				span1: '草莓甜心 元气少女',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/07.jpg')
			},
			{
				active: '#8a347e',
				name: '#M08 霓虹巴士',
				span1: '特调玫瑰紫薯  不显黑的“口紫”',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/08.jpg')
			}
		]
	},
	{
		id: 4,
		defalut: require('@/assets/img/Product/SCYP1.jpg'),
		active: require('@/assets/img/Product/SCYP2.jpg'),
		series: '眼部',
		seriesName: '丝绸综合盘',
		zhName: '酵色「丝绸综合盘」',
		enName: 'Silky Silk Eyeshadow Palette',
		price: '149',
		keyvalue1: '眼影',
		keyvalue2: '眼盘',
		keyvalue3: '眼影盘',
		of: '9g',
		intro: '独特又感性的橘绿粉撞色',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/01.jpg') },
		],
		color: [
			{
				active: '#aaab93',
				name: '#09 绿水夏乐',
				span1: '橘绿粉撞色 高闪透亮啫喱',
				span2: '独特又感性 ',
				color: require('@/assets/img/详情/丝绸素材/丝绸眼盘/09.jpg')
			}
		]
	},
	{
		id: 5,
		defalut: require('@/assets/img/Product/SCGG1.jpg'),
		active: require('@/assets/img/Product/SCGG2.jpg'),
		series: '颊彩',
		seriesName: '丝绸高光',
		zhName: '酵色「丝绸高光」',
		enName: 'Silky Silk Highlighter',
		price: '69',
		of: '3.6-4.5g',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',

		intro: '自然水光感 为亚洲暖黄和冷白皮定制',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸高光/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸高光/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/01.jpg') },
		],
		color: [
			{
				active: '#e5e0da',
				name: '#06 月落银湖',
				span1: '细密珍珠白',
				span2: '如湖面洒落的粼粼银光',
				color: require('@/assets/img/详情/丝绸素材/丝绸高光/06.jpg')
			},
			{
				active: '#d7c3c3',
				name: '#07 香槟粉梦',
				span1: '月光香槟金',
				span2: '斟满少女的午后美梦',
				color: require('@/assets/img/详情/丝绸素材/丝绸高光/07.jpg')
			}
		]
	},
	{
		id: 6,
		defalut: require('@/assets/img/Product/BKCY1.jpg'),
		active: require('@/assets/img/Product/BKCY2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳镜面唇釉',
		intro: '轻薄水感 锁住镜面玻璃唇',
		zhName: '酵色「贝壳镜面唇釉」',
		enName: 'Spiral Shell Glazed Lipgloss',
		price: '79',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',

		of: '3.2g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#942825',
				name: '#01 杨枝甘露',
				span1: '纯欲橘调  可盐可甜',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/01.jpg'),
			},
			{
				active: '#851726',
				name: '#02 葡萄冻冻',
				span1: '葡萄细闪 明媚气质',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/02.jpg')
			},
			{
				active: '#a5212b',
				name: '#04 草莓汽酒',
				span1: '软糯草莓 俏皮活力',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/04.jpg')
			},
			{
				active: '#8a2214',
				name: '#06 脏脏奶咖',
				span1: '脏脏茶棕 复古百搭',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/06.jpg')
			},
			{
				active: '#9b3a3d',
				name: '#07 鸳鸯奶茶',
				span1: '清爽豆沙 纯欲轻熟',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/07.jpg')
			},
			{
				active: '#8f3529',
				name: '#08 焦糖奶茶',
				span1: '焦糖橘棕 日杂氛围',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/08.jpg')
			},
			{
				active: '#bd4851',
				name: '#09 桃心水母',
				span1: '肉桂蜜粉 甜美丰盈',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/09.jpg')
			},
			{
				active: '#be5957',
				name: '#10 海盐奶咖',
				span1: '裸感奶茶色 显色温柔',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/10.jpg')
			},
			{
				active: '#b7364e',
				name: '#11 半熟葡萄',
				span1: '果味十足 轻熟妩媚',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/11.jpg')
			},
			{
				active: '#ad3e48',
				name: '#12 柿子茶冻',
				span1: '红茶果冻 半熟少女',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/12.jpg')
			},
			{
				active: '#84141c',
				name: '#31 逃离银河',
				span1: '浓郁血浆色 气场显白',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/13.jpg')
			}
		]
	},
	{
		id: 7,
		defalut: require('@/assets/img/Product/BKYP1.jpg'),
		active: require('@/assets/img/Product/BKYP2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳眼盘',
		intro: '三重质地闪片 点染灵动双眸',
		zhName: '酵色「贝壳综合盘」',
		enName: 'Spiral Shell Eyeshadow Palette',
		price: '149',
		keyvalue1: '眼影',
		keyvalue2: '眼盘',
		keyvalue3: '眼影盘',

		of: '9g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳眼盘/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳眼盘/d4.jpg') },
		],
		color: [
			{
				active: '#a47258',
				name: '#07 金棕日出',
				span1: '暖调层次感配色 粉调温暖细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/07.jpg')
			},
			{
				active: '#c58b95',
				name: '#08 粉雾漂流',
				span1: '梦幻粉紫组合 三重质地闪片',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/贝壳眼盘/08.jpg')
			}
		]
	},
	{
		id: 8,
		defalut: require('@/assets/img/Product/BKSH1.jpg'),
		active: require('@/assets/img/Product/BKSH2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳腮红',
		zhName: '酵色「贝壳腮红」',
		enName: 'Spiral Shell Blush',
		intro: '天然好气色 点缀清透红润',
		price: '79',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',

		of: '3.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3c3ba',
				name: '#N03 迷路晨雾',
				span1: '自然裸粉 少女气色',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n03.jpg')
			},
			{
				active: '#c28f8b',
				name: '#N04 糖渍早樱',
				span1: '灰调肉粉 清透细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/N04.jpg')
			},
			{
				active: '#af7178',
				name: '#N05 碎金玫瑰',
				span1: '烟熏玫瑰 朦胧偏光',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n05.jpg')
			},
			{
				active: '#a16b5f',
				name: '#B03 肉桂赤岩',
				span1: '高级暖棕 细腻金闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/b03.jpg')
			},
		]
	},
	{
		id: 9,
		defalut: require('@/assets/img/Product/BKGG1.jpg'),
		active: require('@/assets/img/Product/BKGG2.jpg'),
		series: '颊彩',
		seriesName: '贝壳高光',
		intro: '轻盈细腻 高级光泽',
		zhName: '酵色「贝壳高光」',
		enName: 'Spiral Shell Highlighter',
		price: '69',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		of: '3.6 - 4.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d4.jpg') },
		],
		color: [
			{
				active: '#dddddd',
				name: '#01 月光碎石',
				span1: '银色大闪',
				span2: '聚焦闪耀白月光',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/01.jpg')
			},
			{
				active: '#cbb2cd',
				name: '#02 耀眼薄雾',
				span1: '粉紫大闪偏光',
				span2: '甜系妆容必备',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/02.jpg')
			},
			{
				active: '#deb599',
				name: '#03 日光之吻',
				span1: '自然香槟金细闪',
				span2: '湿漉漉的水光感',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/03.jpg')
			},
			{
				active: '#f3eed5',
				name: '#05 寻迹流星',
				span1: '蓝粉香槟烤粉',
				span2: '清透妆效',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/05.jpg')
			}
		]
	},
	{
		id: 10,
		defalut: require('@/assets/img/Product/BKFB1.jpg'),
		active: require('@/assets/img/Product/BKFB2.jpg'),
		series: '贝壳系列',
		seriesName: '贝壳粉饼',
		zhName: '酵色「贝壳粉饼」',
		enName: 'Spiral Shell Amplifier Powder',
		intro: 'Q弹帖肤不卡粉 清透柔焦伪素颜',
		price: '129',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		of: '10g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg') },
		],
		color: [
			{
				active: '#f1e8d9',
				name: '#01 自然色',
				span1: '为亚洲肤色定制',
				span2: '奶油淡黄色粉体 遮瑕修肤',
				color: require('@/assets/img/详情/贝壳素材/贝壳粉饼/001.jpg')
			}
		]
	},
	{
		id: 11,
		defalut: require('@/assets/img/Product/HPCY1.jpg'),
		active: require('@/assets/img/Product/HPCY2.jpg'),
		series: '唇部',
		seriesName: '琥珀唇釉',
		zhName: '酵色「琥珀唇釉」',
		enName: 'Tortoise Shell Fluffy Matte Lipgloss',
		price: '79',
		of: '3.3g',
		intro: '浓郁色泽 棉柔乳霜质感',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#a9424b',
				name: '#V01 粉褐白桃',
				span1: '温柔豆沙色 明媚动人',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/01.jpg')
			},
			{
				active: '#8e2828',
				name: '#V02 深海珊瑚 ',
				span1: '冷调棕红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/02.jpg')
			},
			{
				active: '#7f161c',
				name: '#V03 酒渍血珀',
				span1: '明艳苹果红 元气女团色',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/03.jpg')
			},
			{
				active: '#902c41',
				name: '#V04 微醺芋泥',
				span1: '烟熏梅子色 高冷气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/04.jpg')
			},
			{
				active: '#84443c',
				name: '#V05 杏仁肉桂',
				span1: '杏仁肉桂色 纯欲氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/05.jpg')
			},
			{
				active: '#932017',
				name: '#V06 枫糖番茄',
				span1: '棕调烂番茄 复古显白',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/06.jpg')
			},
			{
				active: '#78241f',
				name: '#V07 琥珀糖浆',
				span1: '质感脏橘 迷人风情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/07.jpg')
			},
			{
				active: '#822529',
				name: '#V08 糖霜枣泥',
				span1: '浓郁泥枣红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/08.jpg')
			},
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/10.jpg')
			},
			{
				active: '#a42316',
				name: '#V11 坠落花火',
				span1: '暖调红梨色 南法热情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/11.jpg')
			},
			{
				active: '#b0523e',
				name: '#V12 迷雾陶土',
				span1: '奶杏土橘色 温柔纯欲',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/12.jpg')
			},
			{
				active: '#ac3d3a',
				name: '#V13 半醉桃气',
				span1: '玫瑰蜜桃调 软糯少女',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/13.jpg')
			}
		]
	},
	{
		id: 12,
		defalut: require('@/assets/img/Product/HPYP1.jpg'),
		active: require('@/assets/img/Product/HPYP2.jpg'),
		series: '琥珀系列',
		seriesName: '琥珀综合盘',
		zhName: '酵色「琥珀综合盘」',
		enName: 'Tortoise Shell Eyeshadow Palette',
		price: '149',
		of: '9g',
		keyvalue1: '眼影',
		keyvalue2: '眼盘',
		keyvalue3: '眼影盘',
		intro: '复古摩登 透明感纯欲妆',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/04.jpg') },
		],
		color: [
			{
				active: '#db893b',
				name: '#04 黄棕琥珀',
				span1: '橘棕色彩 日常实用',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/004.jpg')
			},
			{
				active: '#e5b3a7',
				name: '#05 灰粉琥珀',
				span1: '灰粉裸妆 温柔细腻',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/005.jpg')
			}
		]
	},
	{
		id: 13,
		defalut: require('@/assets/img/Product/HPSH1.jpg'),
		active: require('@/assets/img/Product/HPSH2.jpg'),
		series: '颊彩',
		seriesName: '琥珀腮红',
		intro: '微醺色泽 日杂氛围感',
		zhName: '酵色「琥珀腮红」',
		enName: 'Tortoise Shell Blush',
		price: '79',
		of: '3.5g',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3bfb9',
				name: '#N01 玫瑰烤奶',
				span1: '肉粉玫瑰色 温柔气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n01.jpg')
			},
			{
				active: '#bc7c6a',
				name: '#N02 山茶拿铁',
				span1: '棕调土咖色 日系加持',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n02.jpg')
			},
			{
				active: '#c28a74',
				name: '#B01 焦糖银杏',
				span1: '焦糖奶橘色 慵懒氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b01.jpg')
			},
			{
				active: '#c58073',
				name: '#B02 树莓朗姆',
				span1: '微醺豆沙色 黄皮挚爱',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b02.jpg')
			}
		]
	},
	{
		id: 14,
		defalut: require('@/assets/img/Product/YXKCY1.jpg'),
		active: require('@/assets/img/Product/YXKCY2.jpg'),
		series: '唇部',
		seriesName: 'Y2K唇釉',
		zhName: '酵色「Y2K唇釉」',
		enName: 'Future Nostalgia Matte Lipgloss',
		price: '79',
		of: '3.2g',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		intro: '跳跃亮色 辣妹必备',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/1.jpg') },
		],
		color: [
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/10.jpg')
			}
		]
	},
	{
		id: 15,
		defalut: require('@/assets/img/Product/Y2KYP1.jpg'),
		active: require('@/assets/img/Product/Y2KYP2.jpg'),
		series: 'Y2K系列',
		seriesName: '千禧眼盘',
		zhName: '酵色「千禧眼盘」',
		enName: 'Future Nostalgia Eyeshadow Palette',
		intro: '电光金属外壳 高彩火龙果撞色',
		price: '149',
		keyvalue1: '眼影',
		keyvalue2: '眼盘',
		keyvalue3: '眼影盘',
		of: '9g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/04.jpg') },
		],
		color: [
			{
				active: '#c02b60',
				name: '#06 千禧粉盘',
				span1: '电光金属 高彩撞色',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/千禧眼盘/006.jpg')
			},
		]
	},
	{
		id: 16,
		defalut: require('@/assets/img/Product/ZX1.jpg'),
		active: require('@/assets/img/Product/ZX2.jpg'),
		series: '底妆',
		seriesName: '小奶条遮瑕',
		zhName: '酵色「小奶条遮瑕」',
		enName: 'Winter Sweet Tip Concelear',
		price: '69',
		of: '4.5g',
		keyvalue1: '',
		keyvalue2: '',
		keyvalue3: '',
		intro: '痘肌可用 温和遮瑕无负担',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/遮瑕/01.jpg'), bigUrl: require('@/assets/img/详情/遮瑕//001.jpg') },
			{ index: 2, url: require('@/assets/img/详情/遮瑕/02.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/002.jpg') },
			{ index: 3, url: require('@/assets/img/详情/遮瑕/03.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/003.jpg') },
			{ index: 3, url: require('@/assets/img/详情/遮瑕/04.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/004.jpg') },
		],
		color: [
			{
				active: '#f7e9dc',
				name: '#0.5 白皙色',
				span1: '适合面部提亮或泪沟遮瑕',
				span2: '   ',
				color: require('@/assets/img/详情/遮瑕/0.5.jpg')
			},
			{
				active: '#f3dfcd',
				name: '#1 亮肤色',
				span1: '适合偏白肤色遮瑕',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/1.jpg')
			},
			{
				active: '#eed7c3',
				name: '#2 自然色',
				span1: '适合自然偏黄肤色遮瑕',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/2.jpg')
			},
			{
				active: '#dfc3af',
				name: '#3 裸橘色',
				span1: '适合用在粉底前 中和黑眼圈',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/3.jpg')
			}
		]
	},
]